import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[clickOutside]',
})
export class ClickOutsideDirective {
    @Output() clickOutside = new EventEmitter();

    constructor(private elementRef: ElementRef<HTMLDivElement>) {}

    @HostListener('document:click', ['$event.target'])
    onClick(targetElement: HTMLElement) {
        const clickedInside = this.elementRef.nativeElement.contains(targetElement);

        let clickedInsideMainMenu = false;
        const menuItem = document.getElementById('menu-items');
        if (menuItem) {
            clickedInsideMainMenu = menuItem.contains(targetElement);
        }

        if (!clickedInside && !clickedInsideMainMenu && targetElement.parentNode) {
            this.clickOutside.emit(targetElement);
        }
    }
}
