import { TitleCasePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { BaseUserProfileTabComponent } from 'app/components/user-profile/base-user-profile-tab.component';
import { FosterSearchPreferencesInterface, ParentSearchPreferencesInterface, User, YearsExperience } from 'app/models/api/user';
import { UserSubRouteType } from 'app/routing/route-type';
import { allWeekDays } from 'app/types';
import { formatDistance } from 'date-fns';
import { Clipboard } from '@angular/cdk/clipboard';
import { Util } from 'app/utils/utils';

const searchBabysittersWeights = {
    distance: 50,
    lastSearchActivity: 30,
    lastSearchActivityConst: 50,
    receivedMessageCount: 50,
    babyExperience: 30,
    availability: 42,
    premium: 10,
    avatar: 25,
    aboutLength: 25,
    neutralRecommendations: 50,
    positiveRecommendations: 65,
    childrenCount: 50,
};
const searchParentsWeights = {
    distance: 50,
    lastSearchActivity: 30,
    lastSearchActivityConst: 50,
    receivedMessageCount: 50,
    babyExperience: 30,
    availability: 42,
    premium: 10,
    avatar: 10,
    maxBabysitChildren: 50,
    receivedInvitesCountLastDay: 100,
};

export type AllWeightsTypes = typeof searchBabysittersWeights & typeof searchParentsWeights;
export type SomeWeightsTypes = typeof searchBabysittersWeights | typeof searchParentsWeights;

const weightsLabels: Record<keyof AllWeightsTypes, { full: string; short: string }> = {
    distance: {
        full: 'distance',
        short: 'distance',
    },
    lastSearchActivity: {
        full: 'lastSearchActivity',
        short: 'LSA',
    },
    lastSearchActivityConst: {
        full: 'LSAconst',
        short: 'LSAconst',
    },
    receivedMessageCount: {
        full: 'Rec. message count',
        short: 'Message rec.',
    },
    babyExperience: {
        full: 'babyExperience',
        short: 'baby exp.',
    },
    availability: {
        full: 'availability',
        short: 'availability',
    },
    premium: {
        full: 'premium',
        short: 'premium',
    },
    avatar: {
        full: 'avatar',
        short: 'avatar',
    },
    aboutLength: {
        full: 'about length',
        short: 'about length',
    },
    neutralRecommendations: {
        full: 'neutral reviews',
        short: 'neutral rev',
    },
    positiveRecommendations: {
        full: 'positive reviews',
        short: 'positive rev',
    },
    childrenCount: {
        full: 'childrenCount',
        short: 'children',
    },
    maxBabysitChildren: {
        full: 'maxBabysitChildren',
        short: 'Max. children',
    },
    receivedInvitesCountLastDay: {
        full: 'Rec. invites count',
        short: 'Invittes rec.',
    },
};

type MatchUser = User & {
    comparisonLines: string[];
    weightsStats: { value: number; name: string; highlighted: boolean }[];
};

@Component({
    selector: 'matches-tab',
    templateUrl: './matches-tab.component.html',
    styleUrls: ['./matches-tab.component.less'],
})
export class MatchesTabComponent extends BaseUserProfileTabComponent {
    UserSubRouteType = UserSubRouteType;

    formOptions: { key: keyof SomeWeightsTypes; label: string }[] = [];
    weights: SomeWeightsTypes = searchParentsWeights;
    totalCount?: number;
    matches: MatchUser[] = [];
    comparisonLinesMainUser: string[] = [];

    private clipboard = inject(Clipboard);

    ngOnInit() {
        this.comparisonLinesMainUser = this.comparisonLines(this.user);

        this.setWeightsToDefault();
        this.formOptions = Util.keysOf(this.weights ?? {}).map(key => {
            return {
                key,
                label: weightsLabels[key].full,
            };
        });

        this.performSearch();
    }

    setWeightsToDefault() {
        this.weights = this.user.isParent ? { ...searchBabysittersWeights } : { ...searchParentsWeights };
    }

    performSearch() {
        if (!this.weights) {
            return;
        }

        this.userApiService.getMatchesForUser(this.user, this.weights).subscribe(response => {
            this.totalCount = response.meta?.totalCount;
            this.matches = response.data.map(user => {
                const newUser = user as MatchUser;
                newUser.comparisonLines = this.comparisonLines(user);
                newUser.weightsStats = Util.entries(user.meta?.relevanceSortingStats.weights ?? {}).map(([key, value]) => {
                    const roundedValue = Math.round(value);
                    return {
                        value: roundedValue,
                        name: weightsLabels[key as keyof AllWeightsTypes].short,
                        highlighted: roundedValue >= (this.weights?.[key] ?? 0),
                    };
                });
                return newUser;
            });
        });
    }

    copyWeights() {
        this.clipboard.copy(JSON.stringify(this.weights));
    }

    mainInfoForUser(user: User) {
        let str = `${user.meta?.distance.kilometers}km`;
        if (user.isPremium) {
            str += ', Premium';
        }
        str += `, Online ${formatDistance(new Date(user.lastLoginDate ?? ''), new Date(), { addSuffix: true })}`;
        return str;
    }

    yearsExprerience(user: User) {
        const experience = user.fosterProperties?.yearsOfExperience ?? YearsExperience.none;
        switch (experience) {
            case YearsExperience.none:
                return 'no experience';
            case YearsExperience.one:
                return '1 year experience';
            case YearsExperience.moreThanFive:
                return '> 5 years experience';
            default:
                return `${experience} years experience`;
        }
    }

    // eslint-disable-next-line complexity
    private comparisonLines(user: User) {
        const lines: string[] = [];
        lines.push(
            [
                user.afterSchoolAvailability ? 'after-school' : undefined,
                user.occasionalAvailability ? 'occasional' : undefined,
                user.regularAvailability ? 'regular' : undefined,
            ]
                .filter(item => item)
                .aggregatedDescription() ?? '-',
        );
        lines.push(
            allWeekDays
                .map(item => {
                    const ret = item.substring(0, 3).toUpperCase();
                    const selected = Object.values(user.availability?.[item] ?? {}).some(item => item);
                    return selected ? `<b>${ret}</b>` : ret;
                })
                .join(' - '),
        );
        lines.push(
            user.isParent
                ? `has ${user.children.length} ${user.children.length > 1 ? 'children' : 'child'}`
                : `Max ${(user.searchPreferences as FosterSearchPreferencesInterface).maxChildren} ${
                      (user.searchPreferences as FosterSearchPreferencesInterface).maxChildren > 1 ? 'children' : 'child'
                  }`,
        );
        lines.push(
            user.isParent
                ? `aged ${user.children.map(item => item.age).aggregatedDescription(' and ')}`
                : `Exp with ${Object.entries(user.fosterProperties?.ageGroupExperience ?? {})
                      .map(([key, value]) => (value ? key : undefined))
                      .filter(item => item)
                      .aggregatedDescription(' and ')}`,
        );
        lines.push(
            (user.isParent
                ? (user.searchPreferences as ParentSearchPreferencesInterface).hourlyRates
                      ?.map(hourlyRate => {
                          return this.countrySettings?.hourlyRateOptions.find(item => item.value === hourlyRate)?.label ?? '-';
                      })
                      ?.aggregatedDescription(' and ') ?? '-'
                : this.countrySettings?.hourlyRateOptions.find(item => item.value === user.fosterProperties?.averageHourlyRate)?.label ??
                  '-') + ' / hour',
        );
        lines.push(
            new TitleCasePipe().transform(
                user.isParent
                    ? (user.searchPreferences as ParentSearchPreferencesInterface).chores?.join(', ') ?? '-'
                    : Object.entries(user.fosterProperties?.fosterChores ?? {})
                          .map(([key, value]) => (value ? key : undefined))
                          .filter(item => item)
                          .aggregatedDescription(', ') ?? '-',
            ),
        );
        return lines;
    }
}
