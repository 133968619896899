import { BaseParser } from 'app/services/api/parsers/base-parser';
import { ServerResponseData } from 'app/models/api/response';
import { ParserMap } from 'app/services/api/parsers/response-parser';
import { User } from 'app/models/api/user';
import { compareDesc } from 'date-fns';

export class UserParser extends BaseParser {
    parse(data: ServerResponseData, included: ServerResponseData[], parsersMap: ParserMap) {
        const item = super.parseBase(User, data);
        item.warnings = this.getRelationship('warnings', data, included, parsersMap) ?? [];
        item.children = this.getRelationship('children', data, included, parsersMap) ?? [];
        item.devices = this.getRelationship('devices', data, included, parsersMap) ?? [];
        item.photos = this.getRelationship('photos', data, included, parsersMap) ?? [];
        item.payments = this.getRelationship('payments', data, included, parsersMap) ?? [];
        item.payments.sort((a, b) => {
            return compareDesc(new Date(a.created), new Date(b.created));
        });
        item.recommendations = this.getRelationship('recommendations', data, included, parsersMap) ?? [];
        return item;
    }
}
