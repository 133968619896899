import { Injectable, EventEmitter } from '@angular/core';
import { Locale } from 'app/models/api/locale';
import { ApiService } from 'app/services/api/api.service';
import { map, tap } from 'rxjs/operators';
import { ResponseParser } from 'app/services/api/parsers/response-parser';

@Injectable({
    providedIn: 'root',
})
export class LocaleService {
    static readonly defaultLocale = 2; // 2 is EN locale id
    changed = new EventEmitter<Locale[]>();

    private _locales: Locale[] = [];
    get locales() {
        if (this._locales.length === 0 && !this.loading) {
            this.getAll().subscribe();
        }
        return this._locales;
    }
    private set locales(items: Locale[]) {
        this._locales = items;
        this.changed.emit(this._locales);
    }

    private loading = false;

    constructor(
        private apiService: ApiService,
        private responseParser: ResponseParser,
    ) {}

    getAll() {
        this.loading = true;
        return this.apiService.get('/gem/locales', { mainSubpath: true }).pipe(
            map(response => this.responseParser.parseObject<Locale[]>(response)),
            tap(response => {
                this.locales = response.data;
                this.loading = false;
            }),
        );
    }
}
