import { Component, inject, signal } from '@angular/core';
import { BaseUserProfileTabComponent } from 'app/components/user-profile/base-user-profile-tab.component';
import { GeneralApiService } from 'app/services/api/general.api.service';

@Component({
    selector: 'notifications-tab',
    templateUrl: './notifications-tab.component.html',
    styleUrls: ['./notifications-tab.component.less'],
})
export class NotificationsTabComponent extends BaseUserProfileTabComponent {
    payload = '';
    showSendConfirmation = signal(false);
    sendInProgress = signal(false);

    private readonly generalApiService = inject(GeneralApiService);

    protected onUserLoaded() {
        this.payload = JSON.stringify(
            {
                token: this.user.devices?.[0]?.fcmToken ?? '<place your token here>',
                data: {
                    type: 'connection_invites.unused.weekly',
                    click_action: 'search',
                },
                notification: {
                    title: 'Use your free invites now ✨',
                    body: 'Don’t be shy! Local families are searching for sitters like you. Use your free invites to put your profile in the spotlight!',
                },
                android: {
                    notification: {
                        click_action: 'search',
                    },
                },
                webpush: {
                    notification: {
                        click_action: 'search',
                    },
                },
                apns: {
                    payload: {
                        aps: {
                            badge: 5,
                        },
                    },
                },
            },
            null,
            2,
        );
    }

    send() {
        this.sendInProgress.set(true);
        this.generalApiService.sendRawPushNotification(JSON.parse(this.payload) as never).subscribe({
            next: _ => {
                this.sendInProgress.set(false);
                this.showSendConfirmation.set(true);
                setTimeout(() => {
                    this.showSendConfirmation.set(false);
                }, 3_000);
            },
            error: _ => this.sendInProgress.set(false),
        });
    }
}
