import { BaseParser } from 'app/services/api/parsers/base-parser';
import { ServerResponseData } from 'app/models/api/response';
import { Device } from 'app/models/api/device';

export class DeviceParser extends BaseParser {
    parse(data: ServerResponseData) {
        const item = super.parseBase(Device, data);
        return item;
    }
}
