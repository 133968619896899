<ng-template [ngIf]="user.email?.endsWith('@sitly.com')">
    <div class="section-row status-section" [class.success]="showSendConfirmation()">
        Status:
        <div *ngIf="sendInProgress()">sending email...</div>
        <div *ngIf="showSendConfirmation()" class="email-sent-confirmation">Push notification sent</div>
    </div>
    <textarea [(ngModel)]="payload"></textarea>
    <details>
        <summary>Useful information:</summary>
        <ul>
            <li>
                <b>token</b> field is mandatory and prefilled with value of first device from the list below, you can pick any other from
                the list or put your own;
            </li>
            <li>
                <b>data</b> is our own internal info:
                <ul>
                    <li>
                        <b>type</b> - unique push type, used for its handling in apps,
                        <a
                            href="https://github.com/sitly/api/blob/develop/src/services/push-notification.service.ts"
                            target="_blank"
                            rel="noopener noreferrer"
                            >list of used types can be seen in PushNotificationDataInterface</a
                        >
                    </li>
                    <li>
                        <b>click_action</b> - page to open on click - same as in 'android' and 'webpush' but additionally needed here for
                        opening proper page on already launched android app;
                    </li>
                </ul>
            </li>
            <li>
                <a
                    href="https://firebase.google.com/docs/reference/fcm/rest/v1/projects.messages#Message"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Firebase documentation for message format;</a
                >
            </li>
        </ul>
    </details>
    <button class="btn-solid-blue" (click)="send()">Send</button>
</ng-template>

<ng-template [ngIf]="!user.email?.endsWith('@sitly.com')">Push can be sent only to sitly users</ng-template>

<div class="gem-table">
    <div class="gem-table-header">
        <div class="col col-type">Type</div>
        <div class="col col-token">FCM token</div>
        <div class="col col-updated">Updated</div>
    </div>

    <div *ngFor="let device of user.devices">
        <div class="row">
            <div class="col col-type">
                {{ device.deviceType }}
            </div>
            <div class="col col-token">
                {{ device.fcmToken }}
            </div>
            <div class="col col-updated">
                {{ device.updatedAt | std | dfnsFormat: 'yyyy-MM-dd\n HH:mm:ss' }}
            </div>
        </div>
    </div>
</div>
