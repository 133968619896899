import { allUserSubRouteTypes, RouteType, UserSubRouteType } from 'app/routing/route-type';
import { UserApiService } from 'app/services/api/user.api.service';
import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from 'app/components/base.component';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'app/models/api/user';
import { UserWarningApiService, WarnedUserAction } from 'app/services/api/user.warning.api.service';
import { GemUserRole } from 'app/models/api/gem-user';
import { UserService } from 'app/services/user.service';

@Component({
    selector: 'user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.less'],
})
export class UserProfileComponent extends BaseComponent {
    user?: User;
    selectedTab?: UserSubRouteType;
    showActionOptions = false;
    savedShown = false;

    UserSubRouteType = UserSubRouteType;
    GemUserRole = GemUserRole;
    userService = inject(UserService);

    private urlSubscription?: Subscription;

    private router = inject(Router);
    private activeRoute = inject(ActivatedRoute);
    private cd = inject(ChangeDetectorRef);
    private userApiService = inject(UserApiService);
    private userWarningApiService = inject(UserWarningApiService);

    ngOnInit() {
        this.activeRoute.paramMap.pipe(takeUntil(this.destroyed$)).subscribe(params => {
            const userId = params.get('userId');
            if (userId) {
                this.userApiService.getUser(userId).subscribe(res => {
                    this.user = res.data;
                });
            }
        });

        this.selectedTab = this.getTabFromUrl(this.router.url);

        this.urlSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.selectedTab = this.getTabFromUrl(event.url);
            }
        });
    }

    ngOnDestroy() {
        this.urlSubscription?.unsubscribe();
    }

    onUserUpdated(user: User) {
        this.user = user;

        setTimeout(() => {
            this.savedShown = true;
            this.cd.markForCheck();
            setTimeout(() => {
                this.savedShown = false;
                this.cd.markForCheck();
            }, 2000);
        }, 500);
    }

    onUserActionsClicked() {
        this.showActionOptions = !this.showActionOptions;
    }

    save(update: Partial<User>) {
        if (!this.user) {
            return;
        }

        this.userApiService.updateUser(this.user.id, update).subscribe(res => {
            this.onUserUpdated(res.data);
        });
    }

    onQuarantineClicked() {
        if (this.user) {
            const userId = this.user.id;
            this.userWarningApiService
                .postWarnedUsersAction(this.user.quarantinedAt ? WarnedUserAction.ignore : WarnedUserAction.quarantine, [userId])
                .subscribe(() => {
                    this.userApiService.getUser(userId).subscribe(res => {
                        this.user = res.data;
                    });
                });
        }
    }

    onDeleteClicked() {
        const userId = this.user?.id;
        if (userId) {
            this.showAlert({
                title: 'Are you sure you want to delete this user?',
                confirmButtonTitle: 'Yes, delete',
            }).componentInstance.onConfirm.subscribe(() => {
                this.userApiService.deleteUser(userId).subscribe(() => {
                    this.navigationService.navigateTo(RouteType.users);
                });
            });
        }
    }

    private getTabFromUrl(url: string) {
        const urlParts = url.split('/');
        let subRoute = urlParts[urlParts.length - 1] as UserSubRouteType;
        if (!allUserSubRouteTypes.includes(subRoute) && (urlParts[urlParts.length - 2] as never) === UserSubRouteType.messages) {
            subRoute = UserSubRouteType.chat;
        }
        return subRoute;
    }
}
