import { PaymentParser } from 'app/services/api/parsers/payment.parser';
import { MessageParser } from 'app/services/api/parsers/message.parser';
import { ConversationParser } from 'app/services/api/parsers/conversation.parser';
import { PhotoParser } from 'app/services/api/parsers/photo.parser';
import { ChildParser } from 'app/services/api/parsers/child.parser';
import { WarningParser } from 'app/services/api/parsers/warning.parser';
import { BaseParser } from 'app/services/api/parsers/base-parser';
import { GemUserParser } from 'app/services/api/parsers/gem-user.parser';
import { UserParser } from 'app/services/api/parsers/user.parser';
import { CountryParser } from 'app/services/api/parsers/country.parser';
import { RecommendationParser } from 'app/services/api/parsers/recommendation.parser';
import { SensitivePhraseParser } from 'app/services/api/parsers/sensitive-phrase.parser';
import { ParsedResponse, ServerResponse, ServerResponseData, BaseApiModel } from 'app/models/api/response';
import { Injectable } from '@angular/core';
import { SensitivePhraseExclusionParser } from 'app/services/api/parsers/sensitive-phrase-exclusion.parser';
import { TranslationParser } from 'app/services/api/parsers/translation.parser';
import { TranslationGroupParser } from 'app/services/api/parsers/translation-group.parser';
import { SubscriptionParser } from 'app/services/api/parsers/subscription.parser';
import { FeedbackParser } from 'app/services/api/parsers/feedback.parser';
import { LocaleParser } from 'app/services/api/parsers/locale.parser';
import { DeviceParser } from 'app/services/api/parsers/device.parser';

export type ParserMap = Record<string, BaseParser>;

@Injectable({
    providedIn: 'root',
})
export class ResponseParser {
    private dataParserMap: ParserMap = {
        'gem-users': new GemUserParser(),
        'users': new UserParser(),
        'countries': new CountryParser(),
        'warnings': new WarningParser(),
        'children': new ChildParser(),
        'photos': new PhotoParser(),
        'conversations': new ConversationParser(),
        'messages': new MessageParser(),
        'payments': new PaymentParser(),
        'recommendations': new RecommendationParser(),
        'sensitive-phrases': new SensitivePhraseParser(),
        'sensitive-phrase-exclusions': new SensitivePhraseExclusionParser(),
        'translations': new TranslationParser(),
        'translation-groups': new TranslationGroupParser(),
        'subscriptions': new SubscriptionParser(),
        'feedbacks': new FeedbackParser(),
        'locales': new LocaleParser(),
        'devices': new DeviceParser(),
    };

    parseObject<T extends BaseApiModel | BaseApiModel[], M = unknown>(response: ServerResponse): ParsedResponse<T, M> {
        let data: T;
        if (response.data instanceof Array) {
            data = response.data.map(item => this.parseDataObject(item, response.included)) as T;
        } else {
            data = this.parseDataObject(response.data, response.included) as T;
        }

        return {
            links: response.links,
            meta: response.meta as M,
            data,
        };
    }

    private parseDataObject<T extends BaseApiModel>(data: ServerResponseData, included: ServerResponseData[]) {
        return this.dataParserMap[data.type]?.parse(data, included, this.dataParserMap) as T;
    }
}
