import { Component, OnInit } from '@angular/core';
import { TranslationGroup } from 'app/models/api/translation-group';
import { finalize } from 'rxjs/operators';
import { ApiError } from 'app/types';
import { BaseTranslationComponent } from 'app/components/translations/base-translation.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocaleService } from 'app/services/locale.service';

@Component({
    selector: 'add-translation',
    templateUrl: './add.component.html',
    styleUrls: ['./add.component.less'],
})
export class AddTranslationComponent extends BaseTranslationComponent implements OnInit {
    groups: TranslationGroup[] = [];
    errorMsg?: string;
    loading = false;
    localeId = LocaleService.defaultLocale;

    addTranslationForm = new FormGroup({
        translationCode: new FormControl('', {
            validators: [Validators.required],
            updateOn: 'change',
        }),
        translationGroup: new FormControl('', {
            validators: [Validators.required],
            updateOn: 'change',
        }),
        translationContent: new FormControl('', {
            validators: [Validators.required],
            updateOn: 'change',
        }),
    });

    get translationCode() {
        return this.addTranslationForm.controls.translationCode;
    }
    get translationGroup() {
        return this.addTranslationForm.controls.translationGroup;
    }
    get translationContent() {
        return this.addTranslationForm.controls.translationContent;
    }

    ngOnInit() {
        super.ngOnInit();
        this.translationService.getGroups().subscribe(response => {
            this.groups = response.data;
            const groupId = this.storageService.lastUsedTranslationGroupId;
            this.translationGroup.setValue(groupId === undefined ? null : `${groupId}`);
            this.cd.markForCheck();
        });
    }

    saveTranslation() {
        if (this.addTranslationForm.invalid) {
            return;
        }

        const code = this.translationCode.value ?? undefined;
        const content = this.translationContent.value ?? undefined;
        const group = this.translationGroup.value ?? undefined;

        this.errorMsg = '';

        if (this.errorMsg.length > 0) {
            return;
        }

        this.loading = true;
        this.translationService
            .addTranslation({
                groupId: parseInt(group ?? ''),
                translationCode: code,
                translations: [{ localeId: this.localeId, content }],
            })
            .pipe(
                finalize(() => {
                    this.loading = false;
                    this.cd.markForCheck();
                }),
            )
            .subscribe({
                next: () => {
                    this.translationContent.setValue(null);
                },
                error: (err: ApiError) => {
                    this.errorMsg = err.error.errors[0]?.title;
                },
            });
    }

    onInputChange() {
        this.storageService.lastUsedTranslationGroupId = Number(this.translationGroup.value);
        this.errorMsg = undefined;
    }
}
