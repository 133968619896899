import { Component, OnInit, inject, signal } from '@angular/core';
import { Translation } from 'app/models/api/translation';
import { TranslationGroup } from 'app/models/api/translation-group';
import { RouteType, TranslationsSubRouteType } from 'app/routing/route-type';
import { LocaleService } from 'app/services/locale.service';
import { finalize, switchMap } from 'rxjs/operators';
import { BaseTranslationComponent } from 'app/components/translations/base-translation.component';
import { PageEvent } from '@angular/material/paginator';
import { GeneralApiService } from 'app/services/api/general.api.service';
import { validators } from 'app/components/translations/translate/translation-validators';

const DEFAULT_PAGE_SIZE = 10;

export class TranslationItem {
    public sourceValue = '';
    public targetValue = '';
    readonly translationCodeId: number;
    readonly translationCode?: string;
    readonly groupName?: string;

    errors = signal<string[]>([]);

    modified = false;

    constructor(
        readonly translations: Translation[],
        readonly sourceLocaleId: number,
        readonly targetLocaleId: number,
    ) {
        this.sourceValue = this.translations.find(item => item.localeId === sourceLocaleId)?.valueDevelopment ?? '';
        this.targetValue = this.translations.find(item => item.localeId === targetLocaleId)?.valueDevelopment ?? '';
        this.translationCodeId = this.translations[0].translationCodeId;
        this.translationCode = this.translations[0].translationCode;
        this.groupName = this.translations[0].groupName;
        this.validate();
    }

    validate() {
        this.errors.set(
            validators.reduce((accumulator, validator) => {
                const errors = validator(this.sourceValue, this.targetValue);
                if (errors) {
                    accumulator.push(...errors);
                }
                return accumulator;
            }, [] as string[]),
        );
    }
}

@Component({
    selector: 'translate',
    templateUrl: './translate.component.html',
    styleUrls: ['./translate.component.less'],
})
export class TranslateComponent extends BaseTranslationComponent implements OnInit {
    translations: TranslationItem[] = [];
    loading = false;
    searchQuery = '';
    sourceLocaleId = LocaleService.defaultLocale;
    targetLocaleId = LocaleService.defaultLocale;
    groupId = this.storageService.lastUsedTranslationGroupId;
    groups: TranslationGroup[] = [];
    pageNumber = 1;
    totalPages = 0;
    totalCount = 0;
    pageSize = this.storageService.paginatorPageSize ?? DEFAULT_PAGE_SIZE;
    untranslated = false;

    get translationsPublishRouterLink() {
        return ['/', RouteType.translations, TranslationsSubRouteType.publish];
    }
    get editAllowed() {
        return this.gemUser.isAdmin || this.gemUser.locales.some(item => item.localeId === this.targetLocaleId);
    }

    private readonly generalApiService = inject(GeneralApiService);

    ngOnInit() {
        super.ngOnInit();
        this.route.queryParamMap.subscribe(params => {
            this.searchQuery = params.get('query') ?? '';

            const targetLocaleParam = parseInt(params.get('targetLocale') ?? '');
            this.targetLocaleId = Number.isNaN(targetLocaleParam)
                ? +(this.gemUser.locales.find(item => item.id !== `${LocaleService.defaultLocale}`)?.id ?? LocaleService.defaultLocale)
                : targetLocaleParam;

            if (params.get('groupId')) {
                this.groupId = parseInt(params.get('groupId') ?? '');
            }
            if (params.get('pageSize')) {
                this.pageSize = parseInt(params.get('pageSize') ?? '');
            }
            if (params.get('pageNumber')) {
                this.pageNumber = parseInt(params.get('pageNumber') ?? '');
            }
            if (params.get('untranslated')) {
                this.untranslated = params.get('untranslated') === `${true}`;
            }

            this.cd.markForCheck();
            this.search();
        });

        this.translationService.getGroups().subscribe(response => {
            this.groups = response.data;
            if (this.groupId === undefined) {
                this.groupId = 0;
            }
            if (this.groupId) {
                this.onInputChanged();
            }
        });
    }

    saveChanges(value: TranslationItem) {
        if (value.modified) {
            value.validate();

            this.translationService
                .updateTranslations(this.targetLocaleId, [
                    {
                        translationCodeId: value.translationCodeId,
                        content: value.targetValue ?? '',
                    },
                ])
                .subscribe(() => {
                    if (!this.untranslated) {
                        this.search();
                    }
                });
        }
    }

    showDeleteTranslationDialog(translationCodeId: number) {
        this.showAlert({
            title: 'Are you sure you want to delete this translation?',
            confirmButtonTitle: 'Yes, delete',
        })
            .componentInstance.onConfirm.pipe(switchMap(() => this.translationService.deleteTranslation(translationCodeId)))
            .subscribe(() => this.search());
    }

    onInputChanged() {
        this.storageService.lastUsedTranslationGroupId = this.groupId;
        this.tryNavigate(
            this.buildUrl({
                query: this.searchQuery,
                targetLocaleId: this.targetLocaleId,
                groupId: this.groupId,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                untranslated: this.untranslated,
            }),
        );
    }

    onPageChanged(event: PageEvent) {
        this.storageService.paginatorPageSize = event.pageSize;
        this.tryNavigate(
            this.buildUrl({
                query: this.searchQuery,
                targetLocaleId: this.targetLocaleId,
                groupId: this.groupId,
                pageNumber: event.pageIndex + 1,
                pageSize: event.pageSize,
                untranslated: this.untranslated,
            }),
        );
    }

    onUntranslatedToggled(value: boolean) {
        this.untranslated = value;
        this.cd.markForCheck();
        this.onInputChanged();
    }

    resetCache() {
        this.generalApiService.resetCache().subscribe();
    }

    private tryNavigate(url?: string | null) {
        if (url) {
            this.navigationService.navigateByUrl(url);
        }
    }

    private buildUrl({
        query,
        targetLocaleId,
        groupId,
        pageSize,
        pageNumber = 1,
        untranslated,
    }: {
        query: string;
        targetLocaleId: number;
        groupId?: number;
        pageNumber?: number;
        pageSize: number;
        untranslated?: boolean;
    }) {
        if (!targetLocaleId) {
            return undefined;
        }
        return (
            `/translations?targetLocale=${targetLocaleId}` +
            `&groupId=${groupId}${query.length > 0 ? '&query=' + query : ''}` +
            `&pageSize=${pageSize}&pageNumber=${pageNumber}&untranslated=${untranslated}`
        );
    }

    private search() {
        this.loading = true;

        const pageParams = { number: this.pageNumber, size: this.pageSize };
        const locales = [this.sourceLocaleId, this.targetLocaleId];
        this.translationService
            .getTranslations(locales, pageParams, {
                ...(this.groupId ? { groupId: this.groupId } : {}),
                ...(this.searchQuery ? { keyword: this.searchQuery } : {}),
                ...(this.untranslated ? { untranslated: true } : {}),
            })
            .pipe(
                finalize(() => {
                    this.loading = false;
                    this.cd.markForCheck();
                }),
            )
            .subscribe(response => {
                const pairs = response.data.reduce(
                    (accumulator, currentValue) => {
                        if (!accumulator[currentValue.translationCode]) {
                            accumulator[currentValue.translationCode] = [];
                        }
                        accumulator[currentValue.translationCode].push(currentValue);
                        return accumulator;
                    },
                    {} as Record<string, Translation[]>,
                );

                this.translations = Object.keys(pairs).map(key => {
                    return new TranslationItem(pairs[key], this.sourceLocaleId, this.targetLocaleId);
                });

                this.totalCount = response.meta?.totalCount ?? 0;
                this.totalPages = response.meta?.totalPages ?? 0;
                this.cd.markForCheck();
            });
    }
}
