import { ServerResponseData } from 'app/models/api/response';
import { EventEmitter, Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiService } from 'app/services/api/api.service';
import { StorageService } from 'app/services/storage.service';
import { CountrySettings } from 'app/models/api/country-settings-interface';

@Injectable({
    providedIn: 'root',
})
export class GeneralApiService {
    readonly changed = new EventEmitter<CountrySettings>();

    constructor(
        private apiService: ApiService,
        private storageService: StorageService,
    ) {}

    getCountrySettings() {
        return this.apiService.get('/country-settings').pipe(
            switchMap(response => {
                const data = response.data as ServerResponseData[];
                const newConfig = data.reduce((accumulator, current) => {
                    accumulator[current?.attributes?.id as keyof CountrySettings] = current?.attributes?.value as never;
                    return accumulator;
                }, {} as CountrySettings);

                if (newConfig && JSON.stringify(this.storageService.countrySettings) !== JSON.stringify(newConfig)) {
                    this.storageService.countrySettings = newConfig;
                    this.changed.emit(newConfig);
                }

                return new Observable<CountrySettings>(observer => {
                    observer.next(newConfig);
                    observer.complete();
                });
            }),
        );
    }

    resetCache() {
        return this.apiService.post('/cache/reset', { mainSubpath: true, body: { key: 'translations' } });
    }

    sendRawPushNotification(payload: object) {
        return this.apiService.post('/gem/push-notifications', { mainSubpath: true, body: payload });
    }
}
