import { GemUserApiService } from 'app/services/api/gem-user.api.service';
import { Country } from 'app/models/api/country';
import { ApiInterceptor, ApiService } from 'app/services/api/api.service';
import { GemUser, GemUserRole } from 'app/models/api/gem-user';
import { EventEmitter, Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, RoutesRecognized } from '@angular/router';
import { AuthToken } from 'app/models/auth-token';
import { RouteType } from 'app/routing/route-type';
import { NavigationService } from 'app/services/navigation.service';
import { StorageService } from 'app/services/storage.service';
import { AuthApiService } from 'app/services/api/auth.api.service';
import { tap } from 'rxjs/operators';
import { GeneralApiService } from 'app/services/api/general.api.service';
import { CountryCode } from 'app/models/country';

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    readonly onNotAllowedCountry = new EventEmitter();

    get isLoggedIn() {
        return !!this.storageService.user;
    }

    get user(): GemUser {
        return this.authUser;
    }
    set user(userData: GemUser) {
        this.authUser = userData;
        this.storageService.user = JSON.stringify(this.authUser);
    }

    private authUser = new GemUser();

    private storageService = inject(StorageService);
    private navigationService = inject(NavigationService);
    private apiService = inject(ApiService);
    private authApiService = inject(AuthApiService);
    private generalApiService = inject(GeneralApiService);
    private gemUserApiService = inject(GemUserApiService);
    private router = inject(Router);

    constructor() {
        ApiInterceptor.onUnauthorized.subscribe(() => this.signOut());

        const localUser = this.storageService.user ? (JSON.parse(this.storageService.user) as GemUser) : null;
        if (localUser) {
            this.authUser = Object.assign(new GemUser(), localUser);
            this.authUser.countries = localUser.countries.map(item => Object.assign(new Country(), item));
        }

        if (this.isLoggedIn) {
            this.onSessionStarted();
            this.gemUserApiService.getMe().subscribe(res => (this.user = res.data));
        }
    }

    signIn(username: string, password: string, otp: string) {
        this.storageService.clear();

        return this.authApiService.signIn(username, password, otp).pipe(
            tap(response => {
                this.handleAuthResponse(response.data);
            }),
        );
    }

    signOut() {
        this.storageService.clear();
        this.apiService.clearCache();

        this.navigationService.navigateTo(RouteType.login);
    }

    switchCountry(value: string, moveToDefaultRoute = true) {
        if (value !== this.storageService.countryCode) {
            this.storageService.countryCode = value;
            if (moveToDefaultRoute) {
                this.navigationService.navigateToDefaultSignedInRoute().then(() => this.navigationService.reload());
            } else {
                this.navigationService.reload();
            }
        }
    }

    private handleAuthResponse(authToken: AuthToken) {
        this.storageService.token = authToken.token;
        this.user = authToken.user;
        this.storageService.countryCode = this.user.countries[0]?.countryCode;

        this.onSessionStarted();
        this.navigationService.navigateToStoredLocation();
    }

    private onSessionStarted() {
        this.generalApiService.getCountrySettings().subscribe(countrySettings => (this.storageService.countrySettings = countrySettings));
        this.router.events.subscribe(event => {
            if (event instanceof RoutesRecognized) {
                const countryCode = event.state.root.firstChild?.params.country as CountryCode;
                if (countryCode) {
                    if (this.user.countries.map(item => item.countryCode).includes(countryCode)) {
                        this.switchCountry(countryCode, false);
                    } else {
                        this.storageService.countryCode = undefined;
                        this.onNotAllowedCountry.emit();
                    }
                }
            }
        });
    }

    // ---- CanActivate ---- //
    canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        switch (this.navigationService.getRouteType(state.url)) {
            case RouteType.notFound:
            case RouteType.login:
                return true;
            default:
                if (this.user.role === GemUserRole.translator) {
                    return state.url.startsWith('/translations') ? true : this.router.parseUrl('/translations');
                }
                return this.isLoggedIn || this.router.navigate([RouteType.login]);
        }
    }
}
