import { AddGemUserComponent } from 'app/components/settings/add-gem-user/add-gem-user.component';
import {
    ScreeningGeneralSubRouteType,
    ScreeningSubRouteType,
    TranslationsSubRouteType,
    UserSubRouteType,
    RouteType,
} from 'app/routing/route-type';
import { SitlyUsersComponent } from 'app/components/sitly-users/sitly-users.component';
import { NgModule, Type } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from 'app/components/login/login.component';
import { NotFoundComponent } from 'app/components/not-found/not-found.component';
import { SessionService } from 'app/services/session.service';
import { ScreeningComponent } from 'app/components/screening/screening.component';
import { UserProfileComponent } from 'app/components/user-profile/user-profile.component';
import { ScreeningGeneralComponent } from 'app/components/screening/general/general.component';
import { ScreeningPersonalDataComponent } from 'app/components/screening/personal-data/personal-data.component';
import { SettingsComponent } from 'app/components/settings/settings.component';
import { ScreeningSensitivePhrasesComponent } from 'app/components/screening/sensitive-phrases/sensitive-phrases.component';
import { ScreeningExclusionsComponent } from 'app/components/screening/exclusions/exclusions.component';
import { TranslationsComponent } from 'app/components/translations/translations.component';
import { TranslateComponent } from 'app/components/translations/translate/translate.component';
import { AddTranslationComponent } from 'app/components/translations/add/add.component';
import { PublishComponent } from 'app/components/translations/publish/publish.component';
import { GroupsComponent } from 'app/components/translations/groups/groups.component';
import { SubscriptionsComponent } from 'app/components/subscriptions/subscriptions.component';
import { FeedbacksComponent } from 'app/components/feedbacks/feedbacks.component';

const route = (type: RouteType | string, component: Type<unknown>, title: string, pathMatch = 'full', children?: Routes) => {
    return {
        path: type,
        component,
        pathMatch,
        canActivate: [SessionService],
        data: { animation: type },
        children,
        title: `Gem | ${title}`,
    } as Route;
};

const routes: Routes = [
    { path: '', redirectTo: RouteType.login, pathMatch: 'full' },
    route(RouteType.login, LoginComponent, 'Login'),
    route(`:country/${RouteType.users}`, SitlyUsersComponent, 'Search'),
    route(`:country/${RouteType.users}/:userId`, UserProfileComponent, 'Profile', 'prefix', [
        { path: '', redirectTo: UserSubRouteType.profile, pathMatch: 'full' },
        route(UserSubRouteType.profile, UserProfileComponent, 'Profile'),
        route(UserSubRouteType.subscription, UserProfileComponent, 'Subscription'),
        route(UserSubRouteType.messages, UserProfileComponent, 'Messages'),
        route(`${UserSubRouteType.messages}/:chatPartnerId`, UserProfileComponent, 'Messages'),
        route(UserSubRouteType.reviews, UserProfileComponent, 'Reviews'),
        route(UserSubRouteType.logs, UserProfileComponent, 'Logs'),
        route(UserSubRouteType.matches, UserProfileComponent, 'Matches'),
        route(UserSubRouteType.emails, UserProfileComponent, 'Emails'),
        route(UserSubRouteType.notifications, UserProfileComponent, 'Notifications'),
    ]),
    route(`:country/${RouteType.screening}`, ScreeningComponent, 'Grey list', 'prefix', [
        { path: '', redirectTo: ScreeningSubRouteType.general, pathMatch: 'full' },
        route(`${ScreeningSubRouteType.general}`, ScreeningGeneralComponent, 'Grey list', 'prefix', [
            { path: '', redirectTo: `${ScreeningGeneralSubRouteType.greyList}`, pathMatch: 'full' },
            route(ScreeningGeneralSubRouteType.greyList, ScreeningGeneralComponent, 'Grey list'),
            route(ScreeningGeneralSubRouteType.blackList, ScreeningGeneralComponent, 'Black list'),
            route(ScreeningGeneralSubRouteType.suspects, ScreeningGeneralComponent, 'Suspects'),
            route(ScreeningGeneralSubRouteType.quarantined, ScreeningGeneralComponent, 'Quarantined'),
        ]),
        route(ScreeningSubRouteType.personalData, ScreeningPersonalDataComponent, 'Personal data'),
        route(ScreeningSubRouteType.sensitivePhrases, ScreeningSensitivePhrasesComponent, 'Phrases'),
        route(ScreeningSubRouteType.exclusions, ScreeningExclusionsComponent, 'Exclusions'),
    ]),
    route(`:country/${RouteType.subscriptions}`, SubscriptionsComponent, 'Subscriptions'),
    route(`:country/${RouteType.feedbacks}`, FeedbacksComponent, 'User feedback'),
    route(RouteType.settings, SettingsComponent, 'Settings'),
    route(RouteType.translations, TranslationsComponent, 'Translations', 'prefix', [
        { path: '', redirectTo: `${TranslationsSubRouteType.translate}`, pathMatch: 'full' },
        route(TranslationsSubRouteType.translate, TranslateComponent, 'Translations'),
        route(TranslationsSubRouteType.add, AddTranslationComponent, 'Add translations'),
        route(TranslationsSubRouteType.publish, PublishComponent, 'Publish translations'),
        route(TranslationsSubRouteType.groups, GroupsComponent, 'Translations group'),
    ]),
    route(RouteType.addGemUser, AddGemUserComponent, 'Add a GEM user'),
    route('**', NotFoundComponent, 'Page not found!'),
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
